.App {
  background-color: #0d6efd;
}

.img {
  object-position: 0px center;
  object-fit: cover;
  /* width: 100%; */
  height: 460px;
  overflow: hidden;
}

#nav-dropdown::after {
  display: none;
}

.vr {
  margin-right: 20px;
}

#inner-chart {
  width: 38rem;
  height: 27rem;
}

#circle {
  height: 100%;
  width: 80%;
}

#circle-2 {
  height: 100%;
  width: 78%;
}

#chart-2 {
  width: 38rem;
  height: 22rem;
}

.sidb-respons-md {
  background: #3f67df;
  width: 200px;
  min-height: 100%;
}

.sidb-respons {
  display: flex;
  justify-content: center;
}

.pdng {
  font-size: 28px;
  padding-left: 15px;
  padding-top: 10px;
  transform: rotate(-20deg);
}

.pd-top {
  padding-top: 11px;
}

.sml1 {
  font-weight: 500;
  padding-top: 13px;
  font-size: 12px;
  padding-right: 58px;
  display: flex;
  /* align-items: flex-end; */
  padding: 10px;
}

body {
  min-height: 100vh;
}

.pagess {
  padding-bottom: 10px;
  color: rgb(249, 249, 249);
}

.bg-re {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  cursor: pointer;
}

.bg-re :hover {
  background-color: rgba(184, 192, 230, 0.906);
}

.sidebar #sidebarToggle:focus {
  outline: none;
}

@media (max-width: 576px) {
  #inner-chart {
    width: 20.5rem;
    height: 17rem;
  }
  #chart {
    width: 100%;
    height: 100%;
  }
  #chart-2 {
    width: 100%;
    height: 100%;
  }
  #circle {
    width: 100%;
    height: 100%;
  }
  #circle-2 {
    width: 21rem;
    height: 22rem;
  }
}

@media (min-width: 577px) {
  #inner-chart {
    width: 30rem;
    height: 22rem;
  }
  #chart {
    width: 100%;
    height: 100%;
  }
  #chart-2 {
    width: 30.2rem;
    height: 100%;
  }
  #circle {
    width: 89%;
    height: 100%;
  }
  #circle-2 {
    width: 31rem;
    height: 100%;
  }
}

@media (min-width: 992px) {
  #inner-chart {
    width: 38rem;
    height: 27rem;
  }
  #circle {
    height: 100%;
    width: 80%;
  }
  #circle-2 {
    height: 100%;
    width: 78%;
  }
  #chart-2 {
    width: 38rem;
    height: 22rem;
  }
}

@media (max-width: 730px) {
  .sidb-respons {
    width: 89px;
  }
  .smile {
    display: none;
  }
  .sml1 {
    font-weight: bolder;
    justify-content: center;
    padding: 10px;
    padding-right: 30px;
    font-size: 10px;
    /* text-size-adjust: 3px; */
  }
  .padding {
    padding-left: 27px;
  }
  .padding-sm {
    padding-right: 21px;
  }
  .padding-bill {
    position: relative;
    right: 50px;
  }
  .pdng {
    padding-left: 29px;
  }
  .billing {
    text-align: center;
  }
  .dashboard2 {
    padding-left: 23px;
  }
  .dashboard {
    text-align: center;
  }
}

@media (max-width: 730px) {
  .sidb-respons2 {
    min-height: 100%;
  }
}

@media (max-width: 300px) {
  .sidb-respons {
    display: none;
  }
}
